import React, { useRef, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import styled from "styled-components";
import Carousel from "react-material-ui-carousel";
import pic2 from "./yoav.jpeg";
import pic from "./yoav1.jpeg";
import pic3 from "./yoav3.jpeg";
import Biography from "./Biography";
import MusicPlayerSlider from "../components/MusicPlayer/MusicPlayerSlider";
import Contact from "./Contact";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import VideoPlayer from "../components/VideoPlayer/VideoPlayer";
import Studio from "./Studio";
import MyStudio from "./MyStudio.jpeg";
import BLACKcon from "./BLACKcon.jpeg";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import red_back from "./red_back.jpeg";
import grainy from "./grainy.jpeg";
import stu from "./stu.jpeg";
import newsback from "./newsback.jpeg";
import New from "./New";
import ma from "./ma.mp4";
import thoughts from "./thoughts.png";
import RecordingStudioWebsite from "./RecordingStudioWebsite";
const StyledDiv = styled.div`
  margin: auto;
  max-width: 100%;
`;
const FloatingWhatsApp = styled.a`
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #25d366;
  color: white;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  z-index: 1000;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
    background-color: #22c15e;
  }

  svg {
    width: 35px;
    height: 35px;
  }

  @media (max-width: 600px) {
    width: 50px;
    height: 50px;
    bottom: 20px;
    right: 20px;

    svg {
      width: 30px;
      height: 30px;
    }
  }
`;

const WhatsAppIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
  >
    <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
  </svg>
);

const StyledHeader = styled(Typography)`
  && {
    color: white;
    font-family: "Castoro", serif;
    font-size: 5rem; // Default for larger screens
    display: flex;
    padding-top: 2rem; // Adjusted padding to reduce height
    padding-bottom: 1.5rem; // Adjusted padding to reduce height
    z-index: 1;
    justify-content: center;
    letter-spacing: 0.5rem;

    @media (max-width: 960px) {
      font-size: 3rem;
      padding-top: 0.5rem; // Reduced for medium devices
      padding-bottom: 0.5rem; // Reduced for medium devices
    }

    @media (max-width: 600px) {
      font-size: 2rem;
      padding-top: 0.25rem; // Reduced for small devices
      padding-bottom: 0.25rem; // Reduced for small devices
      letter-spacing: 0.3rem;
    }
    @media (max-width: 450px) {
      font-size: 1.5rem;
      display: flex;
      margin-left: 3rem;
      padding-top: 0.7rem; // Reduced for small devices
      padding-bottom: 0.5rem; // Reduced for small devices
      letter-spacing: 0.3rem;
    }
  }
`;

const StyledImage = styled.img`
  width: 100%; // Default width
  height: auto;
  text-transform: uppercase;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: block;
  margin: auto;

  @media (max-width: 960px) {
    // Medium devices
    width: 80%;
  }

  @media (max-width: 600px) {
    // Small devices
    width: 100%;
  }
`;

const StyledHeaderAboutMe = styled(Typography)`
  && {
    color: white;
    width: 100%;
    text-transform: uppercase;
    font-family: "Castoro", serif !important;
    font-size: 5rem;
    margin-bottom: 1rem;
    display: flex;

    justify-content: center;
    letter-spacing: 0.5rem;

    @media (max-width: 600px) {
      font-size: 2.8rem;
      padding-top: 1.5rem;
      padding-bottom: 0.2rem;
      letter-spacing: 0.3rem;
    }
  }
`;
const StyledSubHeader = styled(Typography)`
  && {
    color: black;
    font-family: "Castoro", serif;
    font-size: 1.8rem;
    text-transform: uppercase;
    display: flex;
    font-weight: 300;
    margin-bottom: 1rem;
    justify-content: center;
    /* letter-spacing: 0.1rem; */
    margin-top: -1.6rem;

    @media (max-width: 600px) {
      margin-top: 0rem;
      font-size: 1.2rem;
      letter-spacing: 0.3rem;
      text-align: center;
    }
    @media (max-width: 400px) {
      margin-top: 0rem;
      font-size: 1.2rem;
      letter-spacing: 0.3rem;
      text-align: center;
    }
  }
`;
const StyledHeaderMsic = styled(Typography)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;

    color: white;
    font-family: "Castoro", serif;
    font-size: 5rem;
    text-transform: uppercase;
    display: flex;
    padding-top: 1.4rem;
    margin-bottom: 1rem;
    justify-content: center;
    letter-spacing: 0.5rem;
    @media (max-width: 600px) {
      // Small devices
      font-size: 2.6rem;
      letter-spacing: 0.3rem;
    }
  }
`;

const StyledAboutMeImage = styled.img`
  width: 100%; // Or set a specific width
  height: auto;
  object-fit: cover; // Or 'contain' depending on desired appearance
  margin-top: 1rem;
  border-radius: 10px; // Optional, if you want rounded corners
`;
const StyledContainerSong = styled.div`
  padding: 1rem;
  @media (max-width: 960px) {
    // Medium devices
    padding: 0.5rem;
  }

  @media (max-width: 600px) {
    // Small devices
    padding: 0.25rem;
  }
`;

const StyledAboutMeContainer = styled.div`
  background-color: #63cac6;
  padding: 1rem;

  @media (max-width: 960px) {
    // Medium devices
    padding: 0.5rem;
  }

  @media (max-width: 600px) {
    // Small devices
    padding: 0.25rem;
  }
`;
const StyledContatcContainer = styled.div`
  background-image: url(${BLACKcon});
  background-size: cover;

  padding: 1rem;

  @media (max-width: 960px) {
    // Medium devices
    padding: 0.5rem;
  }

  @media (max-width: 600px) {
    // Small devices
    padding: 0.25rem;
  }
`;
const StyledVideoContainer = styled.div`
  padding: 1rem;
  position: relative;
  padding: 1rem;
  overflow: hidden;
  height: auto; // Adjust height as needed
  @media (max-width: 960px) {
    // Medium devices
    padding: 0.5rem;
  }

  @media (max-width: 600px) {
    // Small devices
    padding: 0.25rem;
  }
`;
const StyledStudioContainer = styled.div`
  background-image: url(${MyStudio});
  background-size: cover;
  height: 70rem;
  background-repeat: no-repeat;

  @media (max-width: 960px) {
    padding: 0.5rem; // Slightly smaller padding on medium screens
  }

  @media (max-width: 600px) {
    height: 28rem;
    padding: 0.25rem; // Minimal padding on small screens
    background-size: contain; // Change background size to contain on small screens
  }
  @media (max-width: 450px) {
    height: 16.3rem;
    padding: 0.25rem; // Minimal padding on small screens
    background-size: contain; // Change background size to contain on small screens
  }
`;
const StyledNewsContainer = styled.div`
  background-image: url(${newsback});
  width: 100%;
  background-repeat: repeat;

  @media (max-width: 960px) {
    padding: 0.5rem; // Adjust padding for medium screens
  }

  @media (max-width: 600px) {
    padding: 0.25rem; // Minimal padding for small screens
  }
`;
const Home = () => {
  const carouselItems = [pic3, pic2, pic]; // Paths to carousel images
  const contactRef = useRef(null);
  const musicRef = useRef(null);
  const aboutMeRef = useRef(null);
  const videoRef = useRef(null);
  const studioRef = useRef(null);
  const newsRef = useRef(null);
  const homeRef = useRef(null);
  const [showAos, setShowAos] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const updateAosVisibility = () => {
      if (window.innerWidth < 600) {
        setShowAos(false);
      } else {
        setShowAos(true);
      }
    };

    // Check on initial mount and subsequent resizes
    updateAosVisibility();
    window.addEventListener("resize", updateAosVisibility);

    // Cleanup the event listener when the component unmounts
    return () => window.removeEventListener("resize", updateAosVisibility);
  }, []);

  useEffect(() => {
    AOS.init({
      offset: 200, // גובה האלמנט מהתחתית
      duration: 1500, // משך האנימציה במילישניות
      easing: "ease-in-sine", // סוג האנימציה
      delay: 240, // עיכוב לפני שהאנימציה מתחילה
      once: false, // אנימציה תתרחש רק פעם אחת
      mirror: false, // האם לאפשר אנימציה כשגוללים חזרה למעלה
    });
    AOS.refresh(); // Refresh AOS to calculate positions correctly if needed
  }, []);
  return (
    <>
      <Navigation
        contactRef={contactRef}
        musicRef={musicRef}
        aboutMeRef={aboutMeRef}
        videoRef={videoRef}
        studioRef={studioRef}
        newsRef={newsRef}
        homeRef={homeRef}
      />
      <StyledHeader>YOAV ASIF</StyledHeader>
      <StyledDiv ref={homeRef}>
        <Carousel
          animation="fade"
          navButtonsAlwaysVisible={true}
          cycleNavigation={true}
          duration={2000}
          interval={4000}
          indicators={false}
        >
          {carouselItems.map((item, i) => (
            <StyledImage key={i} src={item} alt={`Slide ${i}`} />
          ))}
        </Carousel>
      </StyledDiv>
      <div>
        <StyledAboutMeContainer
          ref={aboutMeRef}
          style={{
            color: "black !important",
            backgroundImage: `url(${grainy})`,

            backgroundRepeat: "repeat",
          }}
        >
          <StyledHeaderAboutMe data-aos={showAos ? "fade-up" : undefined}>
            About{" "}
          </StyledHeaderAboutMe>
          <div data-aos="fade-up">
            {" "}
            <Biography />
          </div>
        </StyledAboutMeContainer>
      </div>
      {/* style={{ backgroundColor: "#6dbc90" }} */}
      <StyledContainerSong ref={musicRef}>
        <StyledHeaderAboutMe data-aos="fade-up"> Songs</StyledHeaderAboutMe>
        <div data-aos="fade-up">
          <MusicPlayerSlider />
        </div>
      </StyledContainerSong>

      <StyledNewsContainer ref={newsRef}>
        <StyledHeaderMsic data-aos="fade-up">
          {" "}
          News{" "}
          <img
            src={thoughts}
            alt="Thoughts"
            style={{
              width: "285px",
              height: "auto",
              marginTop: "-1.2rem",
              marginBottom: "0.5rem",
            }}
          />
        </StyledHeaderMsic>

        <New newsRef={newsRef} />
        {/* You can add your News component or content here */}
        {/* <News /> */}
      </StyledNewsContainer>
      <StyledVideoContainer ref={videoRef}>
        <video
          autoPlay
          muted
          loop
          style={{
            position: isSmallScreen ? "fixed" : "absolute",
            width: "100%",
            left: 0,
            top: isSmallScreen ? 400 : 0,
            display: isSmallScreen ? "none" : "block",

            height: isSmallScreen ? "auto" : "100%", // Adjust height on small screens
            objectFit: "cover", // Cover the entire area of the container
            zIndex: -1, // Ensure the video stays in the background
          }}
        >
          <source
            src="https://firebasestorage.googleapis.com/v0/b/mazlot.appspot.com/o/ma.mp4?alt=media&token=9ed3ebf7-00b1-47d2-afe0-06680f835591"
            type="video/mp4"
          />
        </video>

        <StyledHeaderAboutMe data-aos="fade-up"> Videos</StyledHeaderAboutMe>
        <VideoPlayer />
      </StyledVideoContainer>
      <StyledHeaderAboutMe
        style={{
          paddingBottom: "1rem",
          paddingTop: "1.3rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundImage: `url(${stu})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          marginBottom: "0rem",
        }}
      >
        <a
          href="https://haulpaniya.netlify.app/"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "black",
            textDecoration: "none",
          }}
        >
          HAULPANIYA
        </a>
        <StyledSubHeader>
          {isSmallScreen
            ? " yoav asif prod. house"
            : "yoav asif production house"}
        </StyledSubHeader>
      </StyledHeaderAboutMe>
      <StyledStudioContainer ref={studioRef}>
        {/* You can add your Studio component or content here */}
      </StyledStudioContainer>
      <div
        style={{
          backgroundImage: `url(${stu})`,
          backgroundSize: "cover",
          backgroundPosition: "center",

          backgroundRepeat: "repeat",
        }}
      >
        <div data-aos="fade-up">
          <Studio />
        </div>
      </div>
      <RecordingStudioWebsite />
      <FloatingWhatsApp
        href="https://wa.me/972523779937"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Contact us on WhatsApp"
      >
        <WhatsAppIcon />
      </FloatingWhatsApp>
      <StyledContatcContainer ref={contactRef}>
        <StyledHeaderMsic> Contact</StyledHeaderMsic>
        <Contact />
      </StyledContatcContainer>
      <Footer />
    </>
  );
};

export default Home;
