import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import HulpaniaSite from "../src/pages/hulpania/Home";

function App() {
  return (
    <Router>
      <div className="App">
        {/* <SocialMediaSidebar /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/hulpania" element={<HulpaniaSite />} />
        </Routes>

      </div>
      
    </Router>
  );
}

export default App;