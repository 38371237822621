import React from "react";
import { ThemeProvider } from "styled-components";
import CssBaseline from "@mui/material/CssBaseline";

import Typography from "@mui/material/Typography";
import styled from "styled-components";
import Contact from "../Contact";
import RecordingStudioWebsite from "../RecordingStudioWebsite";

// הגדרת ה-theme
const theme = {
  palette: {
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#253148",
    },
    background: {
      default: "#000000",
      paper: "#253148",
    },
    text: {
      primary: "#ffffff",
      secondary: "#b0bec5",
    },
  },
};

// Styled components
const StyledHeaderMsic = styled(Typography)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-family: "Castoro", serif;
    font-size: 5rem;
    text-transform: uppercase;
    display: flex;
    padding-top: 1.4rem;
    margin-bottom: 1rem;
    justify-content: center;
    letter-spacing: 0.5rem;

    @media (max-width: 600px) {
      font-size: 2.6rem;
      letter-spacing: 0.3rem;
    }
  }
`;

// הקומפוננטה הראשית
const Home: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RecordingStudioWebsite />
      <div
        style={{
          backgroundColor: "black",
        }}
      >
        <StyledHeaderMsic>Contact</StyledHeaderMsic>
        <Contact />
      </div>

    </ThemeProvider>
  );
};

export default Home;
